<template>
  <div id= "footer" class="footer-section">
    <div class="first-block-footer-section">
      <div class="contact-section">
        <img src="@/assets/logo.png" alt="" class="logo" />
        <li>
          <u>LightTravel SRL</u> <br />
          <u> 13535960960</u> <br />
          <u> Via Valtellina 56, 20159 Milano</u> <br />
          <u> infocontact@baggysitter.com</u> <br />
          <u> whatsapp: +39 349 691 4882</u>
        </li>
      </div>
      <div class="info-section">
        <div class="menu">
          <h3>MENU</h3>
          <u @click="goto('book')">Home</u>
          <u @click="goto('our-values')">{{ $t('message.menu.ourValues') }}</u>
          <u @click="goto('how-it-works')">{{ $t('message.menu.howItWorks') }}</u>
          <u @click="goto('become-partner')">{{ $t('message.menu.becomePartner') }}</u>
          <u @click="goto('faq')">{{ $t('message.menu.faq') }}</u>
        </div>
        <div class="menu">
          <h3>SOCIAL</h3>
          <a
            href="https://www.instagram.com/baggysitter?igsh=MXZoejR5ZWc0MmNjbQ=="  target="_blank"
            >Instagram</a
          >
          <a href="https://www.linkedin.com/company/baggysitter/"  target="_blank">Linkedin</a>
          <a href="https://www.facebook.com/profile.php?id=61562535171116"  target="_blank"
            >Facebook</a
          >
        </div>
        <div class="menu">
          <h3>LEGAL</h3>

          <!-- Il link ora apre il dialogo -->

          <a
            @click.prevent="dialogPrivacyPolicy = true"
            href="#"
            title="Privacy Policy"
          >
            {{ $t("message.footer.privacyPolicy") }}
          </a>
         

          <!-- Dialogo per la Privacy Policy -->
          <v-dialog v-model="dialogPrivacyPolicy" max-width="600">
            <v-card>
              <v-card-title>{{
                $t("message.footer.privacyPolicy")
              }}</v-card-title>

              <v-card-text>
                <!-- Iframe per caricare la policy di Iubenda -->
                <iframe
                  src="https://www.iubenda.com/privacy-policy/31690683"
                  width="100%"
                  height="400px"
                  frameborder="0"
                ></iframe>
              </v-card-text>

              <v-card-actions>
                <v-btn class="close-popup" @click="dialogPrivacyPolicy = false">
                  {{ $t("message.footer.close") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- COOKIE POLICY -->
          <a
            @click.prevent="dialogCoockiPolicy = true"
            href="#"
            title="Privacy Policy"
          >
            {{ $t("message.footer.cookiePolicy") }}
          </a>

          <!-- Dialogo per la Privacy Policy -->

          <v-dialog v-model="dialogCoockiPolicy" max-width="600">
            <v-card>
              <v-card-title>{{
                $t("message.footer.cookiePolicy")
              }}</v-card-title>

              <v-card-text>
                <!-- Iframe per caricare la policy di Iubenda -->
                <iframe
                  src="https://www.iubenda.com/privacy-policy/31690683/cookie-policy"
                  width="100%"
                  height="400px"
                  frameborder="0"
                ></iframe>
              </v-card-text>

              <v-card-actions>
                <v-btn class="close-popup" @click="dialogCoockiPolicy = false">
                  {{ $t("message.footer.close") }}</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- TERMS & CONDITIONS -->
          <a
            @click.prevent="dialogThermsConditions = true"
            href="#"
            title="therms&Conditions"
          >
            {{ $t("message.footer.therms&Conditions") }}
          </a>

                    <!-- Dialogo per la Therms & Conditions-->
          <v-dialog v-model="dialogThermsConditions" max-width="500">

            <v-card max-width="500">
              <template v-slot:title>
                <div
                  style="
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-bottom: 30px;
                  "
                >
                  <span>{{ $t("message.booking.termsTitle") }}</span>
                  <v-icon
                    @click="dialogThermsConditions = false"
                    class="close-button"
                    >mdi-close</v-icon
                  >
                </div>
              </template>
              <v-card-text>
                <CondizioniServizioView></CondizioniServizioView>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text="CLOSE"
                  @click="dialogThermsConditions = false"
                ></v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
      </div>
    </div>
    <div class="copiright">
      <p>Copyright © 2024 Lightravel srl</p>
    </div>
  </div>
</template>

<script>
import CondizioniServizioView from "@/views/CondizioniServizioView.vue";
export default {
  name: "FooterSection",

  data: () => ({
    drawer: false,
    group: null,
    dialogPrivacyPolicy: false,
    dialogCoockiPolicy: false,
    dialogThermsConditions: false,
  }),

  components: {
    CondizioniServizioView
  },
  watch: {
    group() {
      this.drawer = false;
    },
  },
  methods: {
    goto(refName) {
      var element = document.getElementById(refName).offsetTop - 80;
      window.scrollTo(0, element);
      this.drawer = false;
    },
    changeLocale(locale) {
      console.log("changing locale to: ", locale);
      this.$i18n.locale = locale;
    },
  },
};
</script>

<style>
h2 {
  width: 80%;
  margin-top: 50px;
  font-size: 52px;
  font-weight: bold;

  color: #1c4c34;
}

h3 {
  font-size: 1rem;
  margin-bottom: 20px;
  color: #9fcb78;
}

li {
  list-style: none; /* Rimuove il puntino */
  padding-left: 0; /* Rimuove l'indentazione */
}

.contact-section u {
  cursor: none;
  line-height: 25px;
  font-family: wotfard-regular-webfont;
}
u {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.menu a {
  text-decoration: none;
  color: white;
}

.footer-section {
  background-color: #1c4c34;

  clip-path: polygon(0 0, 100% 10%, 100% 100%, 0% 100%);
  display: flex;
  flex-direction: column;
}

.first-block-footer-section {
  margin-top: 35px;
  padding: 20px;
  display: flex;
  flex-direction: row;
}

.contact-section {
  flex: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-bottom: 30px;
  margin-left: 8%;
}

.logo {
  width: 250px;
  margin-bottom: 30px;
}

.info-section {
  flex: 60%;
  height: 40vh;
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 2%;
  font-family: wotfard-regular-webfont;
}

.menu {
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-top: 40px;

  justify-content: center;
  align-items: start;
}

.iubenda-white {
  background-color: inherit !important;
  color: white !important;
}

.iubenda-embed {
  background-image: none !important;
  color: white;
}
.iubenda-embed:not(.no-brand):not(.iubenda-nostyle) {
  background-color: inherit !important;

  background-image: none !important;
  padding-left: 0 !important;
}

.iubenda-embed:not(.iubenda-nostyle) {
  color: white !important;
}

.iubenda_policy.iubenda_vip_policy.iubenda_fixed_policy .iub_container {
  padding: 0;
}
.copiright {
  width: 100%;
  text-align: center;

  color: white;
  padding: 20px;
}

.copiright p{
  text-align: center;
  font-size: 1.6vw;
}

/* per desktop */
@media (max-width: 600px) {
  h2 {
    width: 100%;
    font-size: 7vw; /* Font-size più piccolo sui dispositivi più grandi */
  }

  .prenota-ora {
    padding: 10px 20px 10px 20px;
  }

  .footer-section {
    flex-direction: column;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  }

  .logo {
    width: 70%;
  }

  .contact-section {
    padding: 20px 0 20px 5px;
    margin-left: 0;
  }
  .info-section {
    justify-content: center;
  }

  .copiright p{
  text-align: center;
  font-size: 3vw;
}
}

@media (max-width: 480px) {
  h2 {
    width: 100%;
    font-size: 8vw;
  }

  .booking-section h2{
    font-size: 24px;
  }
  .prenota-ora {
    padding: 10px 20px 10px 20px;
  }

  .first-block-footer-section {
    flex-direction: column;
  }
  .logo {
    margin-left: 0;
  }
  .info-section {
    flex-direction: column;
    justify-content: center;
  }

  .menu{
    margin-left: 5px;;
    width: 60%;

  }
  .copiright p{
  text-align: center;
  font-size: 3.4vw;
}

}
</style>
