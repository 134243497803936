<template>
  <div class="booking-section-v1">
    <!-- <p>{{ cityName }}</p> -->
    <div class="select-place">
      <div class="progress-image-box">
        <img
          v-if="
            (dropOffLocation === '' ||
              dropOffLocation === $t('message.bookingSectionV1.selectPlace')) &&
            (pickUpLocation === '' ||
              pickupLocation === $t('message.bookingSectionV1.selectPlace')) &&
            !addressDropoffWrong &&
            !addressPickupWrong
          "
          src="@/assets/progress-image/inattivo.png"
          alt=""
          class="progress-image"
        /><img
          v-if="
            pickUpLocation !== '' &&
            pickUpLocation !== $t('message.bookingSectionV1.selectPlace') &&
            (dropOffLocation === '' ||
              dropOffLocation === $t('message.bookingSectionV1.selectPlace')) &&
            !addressDropoffWrong &&
            !addressPickupWrong &&
            isInPolygonPickup
          "
          src="@/assets/progress-image/ritiro-attivo.png"
          alt=""
          class="progress-image"
        />

        <img
          v-if="
            !addressPickupWrong &&
            !addressDropoffWrong &&
            pickUpLocation !== $t('message.bookingSectionV1.selectPlace') &&
            pickUpLocation !== '' &&
            dropOffLocation !== $t('message.bookingSectionV1.selectPlace') &&
            dropOffLocation !== '' &&
            isInPolygonPickup &&
            isInPolygonDropoff
          "
          src="@/assets/progress-image/attivo.png"
          alt=""
          class="progress-image"
        />

        <img
          v-if="
            dropOffLocation !== '' &&
            dropOffLocation !== $t('message.bookingSectionV1.selectPlace') &&
            (pickUpLocation === '' ||
              pickUpLocation === $t('message.bookingSectionV1.selectPlace')) &&
            !addressDropoffWrong &&
            !addressPickupWrong &&
            isInPolygonDropoff
          "
          src="@/assets/progress-image/consegna-attivo.png"
          alt=""
          class="progress-image"
        />

        <img
          v-if="
            addressPickupWrong ||
            addressDropoffWrong ||
            !isInPolygonPickup ||
            !isInPolygonDropoff
          "
          src="@/assets/progress-image/errore.png"
          alt=""
          class="progress-image"
        />
      </div>

      <div class="booking-section-box" id="book">
        <div class="booking-section-input">
          <div
            :class="{
              'pickup-p': !addressPickupWrong,
              'pickup-wrong-p': addressPickupWrong,
            }"
          >
            <p>{{ $t("message.booking.pickupLocation") }}</p>
          </div>

          <GmapAutocomplete
            :class="{
              'autocomplete-pickup-wrong red-border': addressPickupWrong,
              'gmap-overPolygon': !isInPolygonPickup,
            }"
            
            :placeholder="placeholderPickup"
            @address-selected="autocompletePickup"
            @wrong-address="isPickupWrong"
            @is-over-polygon="checkIsOverPolygonPickup"
            @Click="isPickupClicked"
            @blur="isPickupBlur"
          ></GmapAutocomplete>

          <span>{{
            $t("message.bookingSectionV1.gmapAutocomplete.placeholder")
          }}</span>
          <span class="error-gmap" v-if="!isInPolygonPickup">{{
            $t("message.booking.overPolygon")
          }}</span>
        </div>
        <div class="booking-section-input">
          <div
            :class="{
              'dropoff-p': !addressDropoffWrong,
              'dropoff-wrong-p': addressDropoffWrong,
            }"
          >
            <p>{{ $t("message.booking.dropoffLocation") }}</p>
          </div>

          <GmapAutocomplete
            :class="{
              'autocomplete-dropoff-wrong red-border': addressDropoffWrong,
              'gmap-overPolygon': !isInPolygonDropoff,
            }"
            :placeholder="placeholderDropoff"
            @address-selected="autocompleteDropoff"
            @wrong-address="isDropoffWrong"
            @is-over-polygon="checkIsOverPolygonDropoff"
            @Click="isDropoffClicked"
            @blur="isDropoffBlur"
          ></GmapAutocomplete>
          <span>{{
            $t("message.bookingSectionV1.gmapAutocomplete.placeholder")
          }}</span>
          <span class="error-gmap" v-if="!isInPolygonDropoff">{{
            $t("message.booking.overPolygon")
          }}</span>
        </div>
      </div>
    </div>

    <div class="date-times">
      <!-- PICKUP DATE TIME -->
      <v-dialog max-width="500" v-model="isPickupDateTime">
        <template v-slot:activator="{ props: activatorProps }">
          <button
            :class="{'date-button ': !pickUpDateTimeChosen,
              ' date-button-not-chose': pickUpDateTimeChosen
            }"
            v-bind="activatorProps"
          >
            <span v-if="pickupDateTime">{{ pickupDateTime }}</span>
            <span v-else> {{ $t("message.booking.selectPickupTime") }}</span>
          </button>
        </template>

        <template v-slot:default="{}">
          <Calendar2
            @date-time="getDateTimePickup"
            @is-open="closeDialogPickup"
            :dropoffDateTime="dropoffDateTime"
            :titolo="$t('message.booking.selectPickupTime')"
          ></Calendar2>
        </template>
      </v-dialog>

      <!-- DROPOFF DATE TIME -->
      <v-dialog max-width="500" v-model="isDropoffDateTime">
        <template v-slot:activator="{ props: activatorProps }">
          <button
          :class="{'date-button ': !dropOffpDateTimeChosen,
              ' date-button-not-chose': dropOffpDateTimeChosen
            }"
            v-bind="activatorProps"
            :disabled="!isPickupDateTimechosen"
          >
            <span v-if="dropoffDateTime">{{ dropoffDateTime }}</span>
            <span v-else> {{ $t("message.booking.selectDropoffTime") }}</span>
          </button>
        </template>

        <template v-slot:default="{}">
          <v-card>
            <Calendar2
              @date-time="getDateTimeDropoff"
              @is-open="closeDialogDropoff"
              :pickupDateTime="pickupDateTime"
              :titolo="$t('message.booking.selectDropoffTime')"
            ></Calendar2>
          </v-card>
        </template>
      </v-dialog>
    </div>
    <div class="booking-section-row">
      <span
        >{{ $t("message.booking.smallBags") }} <br />
        {{ $t("message.booking.dimSmallBags") }}
      </span>
      <CustomBagsInput
            :class="{'bag-button ': smallbagChosen,
              ' bag-not-chose': !smallbagChosen
            }"
        @number="(value) => getBagsNumber('smallBags', value)"
      ></CustomBagsInput>
    </div>
    <div class="booking-section-row">
      <span
        >{{ $t("message.booking.largeBags") }} <br />
        {{ $t("message.booking.dimLargeBags") }}</span
      >
      <CustomBagsInput 
            :class="{'bag-button ': largebagChosen,
              ' bag-not-chose': !largebagChosen
            }"
        @number="(value) => getBagsNumber('largeBags', value)"
      ></CustomBagsInput>
    </div>
    <div class="booking-section-row">
      <span
        >{{ $t("message.booking.oofBags") }} <br />
        {{ $t("message.booking.dimOofBags") }}</span
      >
      <CustomBagsInput
            :class="{'bag-button ': oofChosen,
              ' bag-not-chose': !oofChosen
            }"
        @number="(value) => getBagsNumber('oofBags', value)"
      ></CustomBagsInput>
    </div>
<!-- 
    <div class="booking-section-row" v-if="fullPrice">
      <v-switch
          v-model="isBookingCancellable"
          color="#9fcb78"
          :label="$t('message.bookingSectionV1.cancellationService')"
          false-value="no"
          true-value="yes"
          @click="applyCancellationService(isBookingCancellable)"
        >
      </v-switch>
    </div>
    -->

    <div v-if="fullPrice && discountedPrice" class="price-preview">
      <div class="prices" v-if="!isDiscountered">
        <div class="full-price underline-decoration">
          {{ fullPrice }}&#8364;
        </div>
        <div class="discounted-price">{{ discountedPrice }}&#8364;</div>
      </div>
      <div class="prices" v-if="isDiscountered">
        <div class="full-price underline-decoration">
          {{ discountedPrice }}&#8364;
        </div>
        <div class="discounted-price">{{ priceVoucher }}&#8364;</div>
      </div>
      <input
        type="text"
        class="voucher-discount"
        :placeholder="$t('message.bookingSectionV1.voucher')"
        @input="getInputVoucher"
      />
    </div>

    <div v-if="!mandatoryFieldsFilled ">
      <button
          class="baggy-button"
          @click="moveToNextSection"
          elevation="2"
          text
          color="#1c4c34"
        >
      {{ $t("message.booking.next") }}
      </button>
    </div>

    <div v-if="mandatoryFieldsFilled">
      <v-dialog max-width="500">
        <template v-slot:activator="{ props: activatorProps }">
          <v-btn
            class="baggy-button"
            style="background-color: #1c4c34; color: white"
            v-bind="activatorProps"
            :disabled="!mandatoryFieldsFilled"
            variant="flat"
            >    {{ $t("message.booking.next") }}</v-btn
          >
        </template>

        <template v-slot:default="{ isActive }">
          <v-card title="">
            <v-card-text>
              {{ $t("message.booking.recommendation") }}
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn  class="modal-button" @click="isActive.value = false">
                {{ $t("message.booking.close") }}
              </v-btn>

              <v-btn class="modal-button"
                  @click="moveToNextSection"> {{ $t("message.booking.next") }} </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </div>

    <div v-if="!mandatoryFieldsFilled && watchNextButtonPressed" class="error-message"  >
      {{ $t("message.booking.errorFields") }}
    </div>

  </div>
</template>

<style scoped>
span {
  font-size: 1vw;
  color: grey;

  font-family: wotfard-regular-webfont;
  text-align: start;
  width: 100%;
  padding: 0 0 0 0px;
}

.error-message {
  color: red;
  font-family: wotfard-regular-webfont;
}

.booking-section-v1 {
  width: 100%;
  /* height: 100%; */

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.booking-section-v1 p {
  font-size: 20px;
  text-align: center;
  padding: 0;
}
.select-place {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  height: 25vh;
  /* padding: 10px 0 20px 0; */
  gap: 1.1vw;
  margin-bottom: 15px;
}

.progress-image-box {
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  width: auto;
  height: 100%;
}
.progress-image {
  width: auto;
  height: 71%;
  margin-top: 1vw;
}

.red-border{
  border: 1px solid red;
  border-radius: 5px;
}

.booking-section-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 40px;
}

.switch {
  width: 100%;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
}

.img-switch {
  width: 28px;
  margin: 15px 0 15px 0;
}

.modal-button {
  color: white;
  background-color: #1c4c34;
  width: 25%;
  cursor: pointer;
  font-size: 20px;
  border-radius: 5px;
  padding: 3px;
}

.baggy-button {
  color: white;
  background-color: #1c4c34;
  width: 100%;
  cursor: pointer;
  font-size: 20px;
  border-radius: 5px;
  padding: 3px;
}

.baggy-button:disabled {
  background-color: rgba(28, 76, 52); /* 60% opacità */
}

.booking-section-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.booking-section-input p {
  width: 100%;
  text-align: start;
  font-size: 1.5vw;
  font-family: wotfard-regular-webfont;
}

.booking-section-input span {
  font-size: 1vw;
}

.pickup-p,
.dropoff-p {
  width: 100%;
  text-align: start;
  text-transform: uppercase;
  color: #1c4c34;
  padding: 0 0 0 0px;
  font-family: wotfard-regular-webfont;
}
.pickup-wrong-p,
.dropoff-wrong-p {
  width: 100%;
  text-align: start;
  text-transform: uppercase;
  color: #ce2b37;
  padding: 0 0 0 0px;
}

.autocomplete-pickup {
  border: 1px solid #1c4c34;
  border-radius: 5px;
}

.autocomplete-pickup-wrong {
  color: #ce2b37;
}

.gmap-overPolygon {
  color: #ce2b37;
}

.error-gmap {
  color: #ce2b37;
}

.autocomplete-dropoff {
  border: 1px solid #1c4c34;
  border-radius: 5px;
}

.autocomplete-dropoff-wrong {
  color: #ce2b37;
}

.message-advise {
  width: 100%;
  color: #ce2b37;
  text-align: start;
  font-family: wotfard-regular-webfont;
  padding: 0;
}
.booking-section .date-times {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.booking-section .date-times .date-button {
  width: 45%;
  border: 1px solid #1c4c34;
  border-radius: 5px;
  padding: 5px;
  border-color: #1c4c34;
  text-shadow: 0.12px 0 #1c4c34, -0.12px 0 #1c4c34, 0 0.12px #1c4c34,
    0 -0.12px #1c4c34;
  box-shadow: none;
}

.booking-section .date-times .date-button:disabled {
  border: 1px solid grey;
}

.date-button span {
  font-size: 18px;
  color: #1c4c34;
}

.date-button:disabled span {
  color: grey;
}

.date-button-not-chose{
  width: 45%;
  border: 1px solid red;
  border-radius: 5px;
  padding: 5px;
  text-shadow: 0.12px 0 #1c4c34, -0.12px 0 #1c4c34, 0 0.12px #1c4c34,
    0 -0.12px #1c4c34;
  box-shadow: none;
}


.bag-not-chose {
  width: 45%;
  border: 1px solid red;
  border-radius: 5px;
  padding: 5px;
  text-shadow: 0.12px 0 #1c4c34, -0.12px 0 #1c4c34, 0 0.12px #1c4c34,
    0 -0.12px #1c4c34;
  box-shadow: none;
}

.bag-button {
  border: none;
}

.booking-section-row {
  display: flex;
  flex-direction: row;
  /* padding: 10px 0 10px 0; */
}

.booking-section-row span {
  text-align: start;
  font-size: 1vw;
  color: #1c4c34;
}

.booking-section .date-time-section {
  display: flex;
  flex-direction: column;
}

.booking-section .date-time-section .button-section {
  margin-top: 5px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.booking-section .date-time-section .selection-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.booking-section .date-time-section p {
  text-align: left;
  margin-left: 20px;
}

.booking-section .date-time-section .btn-box {
  flex-grow: 1;
}

#terms-coditions-span:hover {
  cursor: pointer;
}

.price-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: gray;
}

.prices {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin: 20px;
}

.full-price {
  /* text-decoration: line-through; */
  font-size: 1.9vw;
  padding-top: 0.17vw;
}
.discounted-price {
  color: #1c4c34;
  font-family: Wotfard-Semibold;
  font-size: 1.9vw;
}

.underline-decoration {
  text-decoration: line-through;
}

.voucher-discount {
  width: 100%;
  font-family: wotfard-regular-webfont;
  border: 1px solid #1c4c34;
  border-radius: 5px;
  padding: 0.65vw;
}
@media (width: 1024px) and (height: 1366px) {
  .select-place {
    width: 15vh;
    background-color: #ce2b37;
  }
}
@media (min-width: 681px) and (max-width: 1000px) {
  .booking-section-v1 p {
    margin: 0;
    padding: 10px;
  }
  .booking-section-box {
    flex-direction: row;
    width: 100%;
    padding: 0;
    gap: 50px;
  }
  .progress-image-box {
    display: none;
  }

  .booking-section-row {
    padding: 0;
  }

  .booking-section-row span {
    font-size: 2vw;
  }

  .booking-section-input p {
    font-size: 1.8vw;
    padding: 0;
  }
  .booking-section-input span {
    font-size: 1.45vw;
  }

  .select-place {
    gap: 20px;
    padding: 0;
    height: 12vh;
  }

  .prices {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 6vw;
  }
  .full-price {
    font-size: 2.6vw;
  }
  .discounted-price {
    font-size: 2.6vw;
  }
}

@media (min-width: 481px) and (max-width: 680px) {
  .booking-section-v1 {
    gap: 0;
  }

  .booking-section-v1 p {
    margin: 0;
    padding: 0;
  }
  .select-place {
    gap: 10px;
    height: 24vh;
  }

  .booking-section-box {
    gap: 20px;
  }

  .progress-image-box {
    height: 24vh;
    padding-top: 1vh;
  }
  .terms-conditions {
    font-size: 12px;
  }
  .booking-section-row {
    padding: 0;
  }

  .booking-section-v1 p {
    font-size: 3vw;
  }
  .booking-section-row span {
    font-size: 2vw;
  }

  .booking-section-input span {
    font-size: 2vw;
  }

  .booking-section .date-times {
    padding: 10px 0 10px 0;
  }

  .booking-section .date-times .date-button {
    padding: 5px;
  }

  .prices {
    margin: 1.3vw 0 0.8vw 0;
    gap: 6vw;
    justify-content: center;
  }
  .full-price {
    font-size: 3vw;
  }
  .discounted-price {
    font-size: 3vw;
  }

  .baggy-button {
    margin-top: 10px;
    font-size: 16px;
  }
}

@media (min-width: 481px) {
  span {
    font-size: 14px;
  }
}

@media (min-width: 100px) and (max-width: 480px) {
  span {
    font-size: 12px;
  }

  .select-place {
    padding: 1vw;
    margin-bottom: 2.2vw;
  }

  .booking-section-input p {
    font-size: 3.8vw;
    margin-top: 1vw;
  }

  .progress-image {
    height: 75%;
  }
  .booking-section .date-times .date-button {
    width: 45%;
    font-size: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
  }

  .booking-section-row span {
    font-size: 3vw;
  }

  .booking-section-input span {
    font-size: 2.8vw;
  }

  .date-button span {
    text-align: center;
    font-size: 14px;
  }

  .full-price {
    font-size: 5vw;
  }
  .discounted-price {
    font-size: 5vw;
  }
}
</style>

<script>
import axios from "axios";
import { ref, watch, computed } from "vue";
/* import DateTimeSelection from "./DateTimeSelection.vue";
 */ import GmapAutocomplete from "./GmapAutocomplete.vue";
import { useI18n } from "vue-i18n";
import CustomBagsInput from "./CustomBagsInput.vue";
import { useStore } from "vuex"; // Importa lo store
import Calendar2 from "./Calendar2.vue";
import moment from "moment";

export default {
  name: "BookingSectionV1",
  components: {
    Calendar2,
    GmapAutocomplete,
    CustomBagsInput,
  },

  props: {},
  setup(props, { emit }) {
    const { t } = useI18n();

    const store = useStore();
    // Definisci una computed property per accedere a nameCity
    const cityName = computed(() => store.state.nameCity); // Usa computed per ottenere il valore

    const pickUpLocation = ref("");
    const dropOffLocation = ref("");
    const addressPickupWrong = ref(false);
    const addressDropoffWrong = ref(false);

    const isInPolygonPickup = ref(true);
    const isInPolygonDropoff = ref(true);

    //segnalini per il tocco della tendina gm-autocomplete
    const pickupClicked = ref(false);
    const dropoffClicked = ref(false);

    const isPickupDateTime = ref(false);
    const isDropoffDateTime = ref(false);
    const pickupDateTime = ref(null);
    const dropoffDateTime = ref(null);
    const isPickupDateTimechosen = ref(false);
    const pickUpDateTimeChosen = ref(false);
    const dropOffpDateTimeChosen = ref(false);

    const smallbagChosen = ref(true);
    const largebagChosen = ref(true);
    const oofChosen = ref(true);
    const mandatoryFieldsFilled = ref(false);
    const watchNextButtonPressed = ref(false);
    const isBookingCancellable = ref(null);

    const pickUpDateTimeISO = ref(null);
    const dropoffDateTimeISO = ref(null);

    const smallBags = ref(0);
    const largeBags = ref(0);
    const outOfFormatBags = ref(0);
    const fullPrice = ref(null);
    const discountedPrice = ref(null);

    const voucherInput = ref(null);
    const priceVoucher = ref(null);
    const isDiscountered = ref(false);

    const isPayloadValid = ref(false);

    const isPickupClicked = () => {
      pickupClicked.value = true;
    };

    const isDropoffClicked = () => {
      dropoffClicked.value = true;
    };

    const isPickupBlur = () => {
      pickupClicked.value = false;
    };

    const isDropoffBlur = () => {
      dropoffClicked.value = false;
    };

    // Funzione per ottenere il prezzo (axios)
    const getPricePreview = async () => {
      try {
        // Prima chiamata API: cost-preview
        const costPreviewResp = await axios.post(
          process.env.VUE_APP_API_URL + "/cost-preview",
          {
            smallBags: smallBags.value,
            largeBags: largeBags.value,
            outOfFormatBags: outOfFormatBags.value,
            pickupDateTime: pickUpDateTimeISO.value,
            dropoffDateTime: dropoffDateTimeISO.value,
            isBookingCancellable: isBookingCancellable.value
          }
        );

      
        getMandatoryFields(watchNextButtonPressed);

        // Aggiorna i valori di fullPrice e discountedPrice
        fullPrice.value = costPreviewResp.data["full_price"];
        discountedPrice.value = parseFloat(
          costPreviewResp.data["discounted_price"]
        ).toFixed(2);


        // Esegui la seconda API solo se il voucher è stato inserito (isDiscountered è true)
        if (isDiscountered.value) {
          // Seconda chiamata API: get-voucher
          const voucherResp = await axios.get(
            `${process.env.VUE_APP_API_URL}/get-voucher/${voucherInput.value}`
          );
          // Calcola il prezzo con il voucher
          priceVoucher.value = parseFloat(
            (
              discountedPrice.value -
              (discountedPrice.value * voucherResp.data.voucher.discount) / 100
            ).toFixed(2)
          );
          isDiscountered.value = true;
        }
      } catch (error) {
        console.error("No voucher:", error);
        isDiscountered.value = false; // Se c'è un errore, resetta lo stato
      }
    };


    const getMandatoryFields = async (watchNextButtonPressed) => {
      if(watchNextButtonPressed.value){
        watchNextButtonPressed.value = false;
        return;
      }
      if(
        pickUpLocation.value !== null &&
        pickUpLocation.value !== "" &&
        pickUpLocation.value !== "0" &&
        dropOffLocation.value !== null &&
        dropOffLocation.value !== "" &&
        dropOffLocation.value !== "0" &&
        isInPolygonPickup.value == true &&
        isInPolygonDropoff.value == true &&
        pickUpDateTimeISO.value !== null &&
        dropoffDateTimeISO.value !== null &&
        (smallBags.value > 0 ||
          largeBags.value > 0 ||
          outOfFormatBags.value > 0)){
          mandatoryFieldsFilled.value = true;
      } else{
        mandatoryFieldsFilled.value = false;
      }
    };

    // Watchers per aggiornare i prezzi
    watch(
      [
        smallBags,
        largeBags,
        outOfFormatBags,
        pickupDateTime,
        dropoffDateTime,
        pickUpDateTimeISO,
        dropoffDateTimeISO,
        pickUpLocation,
        dropOffLocation,
        isBookingCancellable
      ],
      getPricePreview
    );

    watch(pickUpDateTimeISO, () => {
      // metodo per controllare se il datetime di partenza è successivo all'orario di arrivo
      if (pickUpDateTimeISO.value != null) {
        if (
          moment(pickUpDateTimeISO.value, "YYYY-MM-DDTHH:mm[Z]").isSameOrAfter(
            moment(dropoffDateTimeISO.value, "YYYY-MM-DDTHH:mm[Z]")
          ) ||
          moment(pickUpDateTimeISO.value, "YYYY-MM-DDTHH:mm[Z]").isSameOrAfter(
            moment(dropoffDateTimeISO.value, "YYYY-MM-DDTHH:mm[Z]").subtract(
              2.5,
              "hours"
            )
          )
        ) {
          //dropoffDateTime.value = t("message.booking.selectDropoffTime");
          dropoffDateTime.value = null;
          dropoffDateTimeISO.value = null;
        }
      }
    }); 


    const applyCancellationService = (value) => {
      isBookingCancellable.value = value;
    };


    const getDateTimePickup = (dateTime) => {
      pickupDateTime.value = moment(dateTime, "YYYY-MM-DDTHH:mm[Z]").format(
        "DD-MM-YYYY, HH:mm"
      ); // data formattata per la lettura dell'utente
      pickUpDateTimeISO.value = dateTime; //data fornattata per il pagamento
      isPickupDateTimechosen.value = true;
      pickUpDateTimeChosen.value = false;

    };

    const getDateTimeDropoff = (dateDropoff) => {
      dropoffDateTime.value = moment(dateDropoff, "YYYY-MM-DDTHH:mm[Z]").format(
        "DD-MM-YYYY, HH:mm"
      ); // data formattata per la lettura dell'utente
      dropoffDateTimeISO.value = dateDropoff; //data fornattata per il pagamento
      dropOffpDateTimeChosen.value = false;
    };

    const closeDialogPickup = (isOpen) => {
      isPickupDateTime.value = isOpen;
    };

    const closeDialogDropoff = (isOpen) => {
      isDropoffDateTime.value = isOpen;
    };

    // Metodi
    const pickedDatetime = (chosenDate) => {
      pickupDateTime.value = chosenDate;
    };

    const droppedDatetime = (chosenDate) => {
      dropoffDateTime.value = chosenDate;
    };

    const moveToNextSection = () => {
      watchNextButtonPressed.value = true;
      mandatoryFieldsFilled.value = true;
      const payload = {
        pickupLocation: pickUpLocation.value,
        dropoffLocation: dropOffLocation.value,
        smallBags: smallBags.value,
        largeBags: largeBags.value,
        outOfFormatBags: outOfFormatBags.value,
        pickupTimestamp:
          pickUpDateTimeISO.value /* .toFormat("yyyy-LL-dd'T'HH:mm'Z'") */,
        dropoffTimestamp:
          dropoffDateTimeISO.value /* .toFormat("yyyy-LL-dd'T'HH:mm'Z'") */,
      };

      // Validazione del payload
      isPayloadValid.value = Object.values(payload).every(
        (value) => value !== null && value !== undefined && value !== "" && 
          (smallBags.value > 0 || 
               largeBags.value > 0 || 
               outOfFormatBags.value > 0) 
      );

      if (!isPayloadValid.value) {
        mandatoryFieldsFilled.value = false;
        watchNextButtonPressed.value = false;

        if(smallBags.value == 0 || 
          largeBags.value == 0 || 
          outOfFormatBags.value == 0) {
            smallbagChosen.value = false;
            largebagChosen.value = false;
            oofChosen.value = false;
          }

        Object.entries(payload).forEach(([key, value]) => {
          if(value === null || value === undefined || value=== ""){

            if(key.match("pickupLocation")){
              addressPickupWrong.value = true;
            }
            if(key.match("dropoffLocation")){
              addressDropoffWrong.value = true;
            }
            if(key.match("pickupTimestamp")){
              pickUpDateTimeChosen.value = true;
            }
            if(key.match("dropoffTimestamp")){
              dropOffpDateTimeChosen.value = true;
            }
            if(smallBags.value > 0 || 
               largeBags.value > 0 || 
               outOfFormatBags.value > 0 ){
                smallbagChosen.value = true;
                largebagChosen.value = true;
                oofChosen.value = true;
            }
          }
        });
        return; // Interrompi se il payload non è valido
      }

      emit("firstStep", {
        payload: payload,
        fullPrice: fullPrice.value,
        discountedPrice: discountedPrice.value,
        priceVoucher: priceVoucher.value,
        isBookingCancellable: isBookingCancellable.value
      });
    };

    // Computed per gestire il placeholder dinamico
    const placeholderPickup = computed(() => {
      return pickUpLocation.value
        ? `${pickUpLocation.value}`
        : t("message.bookingSectionV1.selectPlace");
    });

    const placeholderDropoff = computed(() => {
      return dropOffLocation.value
        ? `${dropOffLocation.value}`
        : t("message.bookingSectionV1.selectPlace");
    });

    const isPickupWrong = (isWrong) => {
      addressPickupWrong.value = isWrong;
    };

    const isDropoffWrong = (isWrong) => {
      addressDropoffWrong.value = isWrong;
    };

    const autocompletePickup = (address) => {
      // Verifica se l'indirizzo è undefined o null
      switch (true) {
        case address === undefined || address === null || address === "":
          pickUpLocation.value = "";

          return address;

        case address === "wrong":
          addressPickupWrong.value = true;
          return address;
        default:
          addressPickupWrong.value = false;

          pickUpLocation.value = address; // Imposta l'indirizzo valido
          return address;
      }
    };

    const checkIsOverPolygonPickup = (value) => {
      isInPolygonPickup.value = value;
    };

    const checkIsOverPolygonDropoff = (value) => {
      isInPolygonDropoff.value = value;
    };

    const hotelSelectedPickup = (hotel) => {
      pickUpLocation.value = hotel;
      addressPickupWrong.value = false;
    };

    const hotelSelectedDropoff = (hotel) => {
      dropOffLocation.value = hotel;
      addressDropoffWrong.value = false;
      emit("placeholder-hotel", hotel);
    };

    const autocompleteDropoff = (address) => {
      // Verifica se l'indirizzo è undefined o null
      switch (true) {
        case address === undefined || address === null || address === "":
          dropOffLocation.value = "";

          return address;

        case address === "wrong":
          addressDropoffWrong.value = true;
          return address;
        default:
          addressDropoffWrong.value = false;

          dropOffLocation.value = address; // Imposta l'indirizzo valido
          return address;
      }
    };

    const getBagsNumber = (type, number) => {
      // Usa l'identificatore per assegnare il valore corretto
      if (type === "smallBags") {
        smallBags.value = parseInt(number, 10);
        smallbagChosen.value = true;
        largebagChosen.value = true;
        oofChosen.value = true;
      }
      if (type === "largeBags") {
        largeBags.value = parseInt(number, 10);
        smallbagChosen.value = true;
        largebagChosen.value = true;
        oofChosen.value = true;
      }
      if (type === "oofBags") {
        outOfFormatBags.value = parseInt(number, 10);
        smallbagChosen.value = true;
        largebagChosen.value = true;
        oofChosen.value = true;
      }
    };

    const getInputVoucher = (input) => {
      axios
        .get(
          `${process.env.VUE_APP_API_URL}/get-voucher/${input.target.value}`,
          {}
        )
        .then((resp) => {
          if (resp.data.voucher.status) {
            priceVoucher.value = parseFloat(
              (
                discountedPrice.value -
                (discountedPrice.value * resp.data.voucher.discount) / 100
              ).toFixed(2)
            );

            voucherInput.value = input.target.value;
            isDiscountered.value = true;
          }
        })
        .catch((error) => {
          isDiscountered.value = false;
          voucherInput.value = null;
          console.error("No Voucher:", error); // Log dell'errore
        });
    };

    // Ritorna le variabili e i metodi da usare nel template
    return {
      isPickupWrong,
      isDropoffWrong,
      autocompletePickup,
      checkIsOverPolygonPickup,
      checkIsOverPolygonDropoff,
      autocompleteDropoff,
      hotelSelectedPickup,
      hotelSelectedDropoff,
      isPickupClicked,
      isPickupBlur,
      isDropoffBlur,
      isDropoffClicked,
      getInputVoucher,

      cityName,
      pickUpLocation,
      dropOffLocation,
      placeholderPickup,
      placeholderDropoff,
      addressPickupWrong, //segnalino del placeolder
      addressDropoffWrong,
      isPickupDateTimechosen,
      isInPolygonPickup,
      isInPolygonDropoff,
      pickUpDateTimeChosen,
      dropOffpDateTimeChosen,
      smallbagChosen,
      largebagChosen,
      oofChosen,

      pickupClicked,
      dropoffClicked,

      getBagsNumber,

      applyCancellationService,
      isPickupDateTime,
      isDropoffDateTime,
      getDateTimePickup,
      getDateTimeDropoff,
      closeDialogPickup,
      closeDialogDropoff,

      pickupDateTime,
      dropoffDateTime,

      smallBags,
      largeBags,
      outOfFormatBags,
      fullPrice,

      voucherInput,
      discountedPrice,
      priceVoucher,
      isDiscountered,

      mandatoryFieldsFilled,
      watchNextButtonPressed,
      pickedDatetime,
      droppedDatetime,
      moveToNextSection,
      getPricePreview,
      getMandatoryFields,
      isBookingCancellable
    };
  },
};
</script>
