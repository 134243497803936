<template>
  <div class="condizioni-servizio" style="color:black;">

    <object
	data="/static/service-general-condition.pdf"
	type="application/pdf"
	width="100%"
	height="900px"
	aria-labelledby="PDF document"
	title="Service General Condition"
>
	<p>
		Your browser does not support PDFs.
		<a href="/static/service-general-condition.pdf">Download the PDF</a>
	</p>
</object>

  </div>
</template>

<script>

export default {
  name: 'CondizioniServizioView',
  methods: {
    goToHome () {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>
.about {
  text-align: center;
  margin-top: 50px;
}


</style>